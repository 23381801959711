@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;700;900&display=swap');



.form-holder {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: 80vh;
}

.form-holder .form-content {
    position: relative;
    text-align: center;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    align-items: center;
    padding: 20px;
}

.form-content .form-items {
    border: 3px solid rgb(19 65 126 / 1);
    padding: 40px;
    display: inline-block;
    width: 100%;
    min-width: 540px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    border-radius: 10px;
    text-align: center;
    -webkit-transition: all 0.4s ease;
    transition: all 0.4s ease;
}

.form-content h3 {
    color: rgb(19 65 126 / 1);
    font-size: 28px;
    font-weight: 600;
    text-transform: uppercase;
}




.form-content p {
    color: rgb(19 65 126 / 1);

    font-size: 17px;
    font-weight: 300;
    line-height: 20px;

}


.form-content label, .was-validated .form-check-input:invalid~.form-check-label, .was-validated .form-check-input:valid~.form-check-label{
    color: rgb(19 65 126 / 1);
}

.form-content input[type=text], .form-content input[type=password], .form-content input[type=email], .form-content select {
    width: 100%;
    padding: 9px 20px;
    text-align: left;
    border: 0;
    outline: 0;
    border-radius: 6px;
    background-color: rgb(19 65 126 / 1);
    font-size: 15px;
    font-weight: 300;
    color: #fff;
    -webkit-transition: all 0.3s ease;
    transition: all 0.3s ease;
 
}


.btn-primary{
    background-color: rgb(19 65 126 / 1) !important;
    outline: none;
    border: 0px;
     box-shadow: none;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary:active{
    background-color: rgb(19 65 126 / 1) !important;
    outline: none !important;
    border: none !important;
     box-shadow: none;
}

.form-content textarea {
    position: static !important;
    width: 100%;
    padding: 8px 20px;
    border-radius: 6px;
    text-align: left;
    background-color: rgb(19 65 126 / 1);
    border: 0;
    font-size: 15px;
    font-weight: 300;
    color: #fff;  
    outline: none;
    resize: none;
    height: 120px;
    -webkit-transition: none;
    transition: none;
    margin-bottom: 14px;


}

.form-content textarea:hover, .form-content textarea:focus {
    border: 0;
    background-color: rgb(19 65 126 / 1);
    color: #fff;
}

input::placeholder {
    color: white !important;
  }

textarea::placeholder {
    color: white !important;
  }
  textarea:-webkit-autofill {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px rgb(19 65 126 / 1) inset;
  }
  input:-webkit-autofill {
    -webkit-text-fill-color: white;
    -webkit-box-shadow: 0 0 0px 1000px rgb(19 65 126 / 1) inset;
  }
.mv-up{
    margin-top: -9px !important;
    margin-bottom: 8px !important;
}

.invalid-feedback{
    color: #ff606e;
}

.valid-feedback{
   color: #2acc80;
}
