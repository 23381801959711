.marker-pin {
  width: 30px;
  height: 30px;
  border-radius: 50% 50% 50% 0;
  background: #c30b82;
  position: absolute;
  transform: rotate(-45deg);
  left: 50%;
  top: 50%;
  margin: -15px 0 0 -15px;
}

.marker-pin::after {
  content: "";
  width: 24px;
  height: 24px;
  margin: 3px 0 0 3px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
}

.custom-div-icon i {
  position: absolute;
  width: 22px;
  font-size: 22px;
  left: 0;
  right: 0;
  margin: 10px auto;
  text-align: center;
}

.custom-div-icon i.awesome {
  margin: 12px auto;
  font-size: 17px;
}

/* Styling Scroll Bar */
::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


.nav-link {
  color: black;
  text-decoration: none;
  margin-right: 20px;
}

.nav-link.activenavBar {
  font-weight: bold;   
}


@media screen and (max-width: 531px) {
  .container { display: flex; flex-flow: column; }
  .four { order: 2;  }
  .three { order: 4; }
  .two { order: 3; }
  .one { order: 1 }

  li > a.nav-link {

    font-size: 13px;
  }
}

.react-tagsinput-tag{
  padding: 5% !important;
}
.react-tagsinput22{
  color:white !important;
}

.react-tagsinput{
  border-color: rgba(0, 0, 0, 0.08) !important;
  color: #424242 !important;
  background-color: #ffffff !important;
}

.bg-green {
  background-color: #006600;
}