.section {
  position: absolute;
  left: 40%;
  top: 250px;
  width: 350px;
  height: 750px;
  padding: 30px 20px;
  background: #e5e3ef;

  align-items: center;
}
.section-pt {
  position: absolute;

  top: 150px;
  width: 350px;
  height: 650px;
  padding: 30px 20px;
  background: #e5e3ef;
  left: 1%;
  right: inherit;
  align-items: center;
}
.langue {
  display: block;
  position: fixed;
  top: 5px;
  right: 20px;
  background: rgb(212, 208, 208);
  width: 250px;
}
.titre {
  font-style: normal;
  text-shadow: beige;
  text-align: center;
  color: blue;
}
.domainSearch {
  font-style: oblique;
  color: black;
  text-align: left;
  font-size: 20px; /*taille du texte*/
}
.countries {
}
/*.input-countries {
  font-style: oblique;
  color: black;
  text-align: right;
  width: 30px;
  height: 20px;
}*/
.buntonSearch {
  position: relative;

  width: 40%;
  left: 50%;
}
.input-domainSearch {
  width: 250px;
  left: 300px;
}
/*.H2 {
  font-style: oblique;
  color: blue;
  text-align: center;
  font-size: 50px;
  color: blue;
  padding: 20px;
}*/
.H21 {
  text-align: center;
  font-size: 50px;
  margin-top: 1px;
  color: blue;
  padding: 0.001%;
}
.select-langage {
  position: relative;
  width: 250px;
  height: 40px;
  background-color: rgb(245, 241, 241);
}
.leaflet-container {
  width: 68vw;
  height: 93vh;
}
/*.section1 {
  position: absolute;

  top: 300px;
  width: 400px;
  height: 750px;
  padding: 30px 40px;
  background: #e5e3ef;
  left: 4%;
  right: inherit;
  align-items: center;
}*/
.H1 {
  font-style: oblique;
  font-size: 25px;
  text-align: right;
  margin-right: -45%;
  text-align: center;

  color: blue;
  padding: 10px;
}
.td {
  border: 0px solid black;
  width: 10%;
  height: 0.5cm;
  font-size: 25px;
  border-collapse: collapse;
  overflow: auto;
}
.table {
  position: absolute;
  top: auto;
  border: 0.3cm solid rgb(243, 234, 234);
  font-size: 20px;
  padding: 30px 20px;
  background: #ffffff;
  padding: 15%;
  right: 18%;
  align-items: center;

  height: 20%;
  overflow: scroll;
}
.case {
  width: 250px;
  left: 450px;
}
.donnees {
  width: 2000px;
  height: 450px;
  margin-left: 40%;
}
.try {
  height: 30px;
  overflow: scroll;
  width: 250px;
  left: 450px;
  background: greenyellow;
  font-size: 20px;
}
* {
  box-sizing: border-box;
}

[class*="col-"] {
  float: left;
  padding: 15px;
}
.home {
  display: block;
  position: fixed;
  top: 0px;

  font-style: oblique;
  font-size: 45px;
  color: red;
  width: 250px;
}
.tle {
  position: fixed;
}

.html {
  font-family: "Lucida Sans", sans-serif;
}
@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  [class*="col-"] {
    width: 100%;
  }
  .leaflet-container {
    width: 78vw;
  }
  .row {
    margin: 0 !important;
  }
  /*[class*="section1"] {
    left: 0px;
    top: 400px;
    width: 336px;

    margin-left: 5%;
  }*/
  [class*="donnees"] {
    top: 850px;
    padding-top: 300px;
    width: 336px;
    height: 150px;
    margin-left: 4%;
  }
  [class*="langue"] {
    width: -2%;
    height: 0px;
    left: 27%;
  }
  [class*="img"] {
    padding: 0px 10px;
    padding-top: 60px;
    height: 260px;
    width: 200px;
  }
  [class*="home"] {
    padding-left: 170px;
    font-size: 25px;
    top: 100px;
  }
  [class*="table"] {
    top: 1550px;
    width: 336px;
    left: 3%;
    margin-left: 4%;
  }
  [class*="H21"] {
    font-size: 25px;
    padding-right: 70px;
    top: 0%;
  }
  [class*="H1"] {
    font-size: 25px;
    margin-top: 840px;
    margin-left: -44%;
  }
  [class*="select-langage"] {
    width: 130px;
    height: 35px;
    left: 47%;
  }
  [class*="td"] {
    width: auto;
    height: auto;
    font-size: 15px;

    border: 0px solid black;
  }
}

@media only screen and (min-width: 600px) {
  /* For tablets: */
  .col-s-1 {
    width: 8.33%;
  }
  .col-s-2 {
    width: 16.66%;
  }
  .col-s-3 {
    width: 25%;
  }
  .col-s-4 {
    width: 33.33%;
  }
  .col-s-5 {
    width: 41.66%;
  }
  .col-s-6 {
    width: 50%;
  }
  .col-s-7 {
    width: 58.33%;
  }
  .col-s-8 {
    width: 66.66%;
  }
  .col-s-9 {
    width: 75%;
  }
  .col-s-10 {
    width: 83.33%;
  }
  .col-s-11 {
    width: 91.66%;
  }
  .col-s-12 {
    width: 100%;
  }
}

@media only screen and (min-width: 770px) {
  /* For desktop: */
  .col-1 {
    width: 8.33%;
  }
  .col-2 {
    width: 16.66%;
  }
  .col-3 {
    width: 25%;
  }
  .col-4 {
    width: 33.33%;
  }
  .col-5 {
    width: 41.66%;
  }
  .col-6 {
    width: 50%;
  }
  .col-7 {
    width: 58.33%;
  }
  .col-8 {
    width: 66.66%;
  }
  .col-9 {
    width: 75%;
  }
  .col-10 {
    width: 83.33%;
  }
  .col-11 {
    width: 91.66%;
  }
  .col-12 {
    width: 100%;
  }
  [class*="donnees"] {
    top: 5px;
    padding-top: 300px;
    width: 1006px;
    height: 450px;
  }
}

/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  div.example {
    font-size: 80px;
  }
  [class*="home"] {
    left: 13%;
  }
}
@media only screen and (max-width: 880px) {
  div.example {
    font-size: 80px;
  }
  [class*="home"] {
    left: 13%;
  }
  /*[class*="section1"] {
    left: 0px;
    top: 300px;
    width: 450px;

    margin-left: 25%;
  }*/
  [class*="donnees"] {
    top: 850px;
    padding-top: 300px;
    width: 750px;
    height: 250px;
    margin-left: 5%;
  }
  [class*="langue"] {
    width: -2%;
    height: 0px;
    left: 50%;
  }
  [class*="img"] {
    padding: 0px 10px;
    padding-top: 60px;
    height: 260px;
    width: 200px;
  }
  [class*="home"] {
    padding-left: 100px;
    font-size: 25px;
    top: 50px;
  }
  [class*="table"] {
    top: 1500px;
    width: 336px;
    left: 2%;
    margin-left: 30%;
  }
  [class*="H21"] {
    font-size: 25px;
    padding-right: 100px;
    margin-top: -10%;
    padding-left: 150px;
  }
  [class*="H1"] {
    font-size: 25px;
    margin-top: 840px;
    margin-left: -30%;
  }
  [class*="select-langage"] {
    width: 130px;
    height: 35px;
    left: 47%;
  }
  [class*="td"] {
    width: auto;
    height: auto;
    font-size: 15px;

    border: 0px solid black;
  }
}

/* If the screen size is 600px or less, set the font-size of <div> to 30px */
@media only screen and (max-width: 600px) {
  div.example {
    font-size: 30px;
  }
  [class*="home"] {
    left: 20%;
  }
  /*[class*="section1"] {
    left: 0px;
    top: 350px;
    width: 350px;

    margin-left: 5%;
  }*/
  [class*="donnees"] {
    top: 850px;
    padding-top: 300px;
    width: 390px;
    height: 250px;
    margin-left: 0%;
  }
  [class*="langue"] {
    width: -2%;
    height: 0px;
    left: 20%;
  }
  [class*="img"] {
    padding: 0px 10px;
    padding-top: 60px;
    height: 260px;
    width: 200px;
  }
  [class*="home"] {
    padding-left: 100px;
    font-size: 25px;
    top: 50px;
  }
  [class*="table"] {
    top: 1550px;
    width: 336px;
    left: 2%;
    margin-left: 5%;
  }
  [class*="H21"] {
    font-size: 20px;
    padding-right: 175px;
    margin-top: 0%;
    padding-left: 0%;
  }
  [class*="H1"] {
    font-size: 25px;
    margin-top: 840px;
    margin-left: -70%;
  }
  [class*="select-langage"] {
    width: 130px;
    height: 35px;
    left: 47%;
  }
  [class*="td"] {
    width: auto;
    height: auto;
    font-size: 15px;

    border: 0px solid black;
  }
}
@media only screen and (orientation: landscape) {
  body {
    background-color: lightblue;
  }
}

.link {
  color: #c52828;
}

.popup {
  overflow: scroll;

  height: 250px;
}

/*.img {
  height: 180px;
  padding: 0px 10px;
  width: 250px;
}*/

#root {
  background-color: #0C2340;
  color: white;
}

.card-hover:hover {
  transform: scale(1.03);
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 10px 20px rgba(0,0,0,0.2);
}

.card-hover {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.white-border-div {
  border: 2px solid white; /* Ceci crée une bordure blanche de 2px */
  border-radius: 10px; /* Ceci arrondit les coins */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Ceci ajoute une ombre */
}

#root {
  background-color: #0C4DA2;
  color: white;
}